<template>
  <div class="content">
    <div class="block block-rounded">
      <div class="block-content block-content-full">
        <div class="py-3 text-center">
          <h2 class="fw-bold mb-2">充值账户余额</h2>
          <h3 class="fs-base fw-medium text-muted mb-0">
            充值余额请进群联系群主
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-xl-4">
        <div class="block block-rounded text-center overflow-hidden">
          <a
            class="d-block bg-gd-dusk fw-semibold text-uppercase py-2"
            href="javascript:void(0)"
          >
            <span class="text-white fs-sm">余额明细</span>
          </a>
          <div class="block-content block-content-full">
            <p class="fs-sm fw-semibold text-uppercase text-muted">
              当前账户余额：
            </p>
            <div class="pb-4">
              <div class="fs-3 fw-bold">{{ user.money }}元</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-xl-4">
        <div class="block block-rounded text-center overflow-hidden">
          <a
            class="d-block bg-gd-sea fw-semibold text-uppercase py-2"
            href="javascript:void(0)"
          >
            <span class="text-white fs-sm">余额充值</span>
          </a>
          <div class="block-content block-content-full">
            <p class="text-muted">
              如果您已经有了
              <strong>充值卡密</strong> 在下方输入框输入并充值即可
            </p>
            <div class="mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  v-model="km"
                  placeholder="请输入你的卡密号码"
                />
                <label class="form-label" for="km">卡密</label>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-sm btn-alt-primary me-1"
              @click="ajax_km_activate()"
            >
              确认充值
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activate } from "@/api/user";
export default {
  name: "money",
  inject: ["reloadUser"],
  data() {
    return {
      km: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  methods: {
    ajax_km_activate() {
      if (this.km === "") {
        this.$message.warning("卡密不能为空");
        return;
      }
      activate({ km: this.km })
        .then((message) => {
          this.$message.success(message);

          this.reloadUser();
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style scoped></style>
